var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass:
          "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
      },
      [_vm._m(0)]
    ),
    _vm.infoPlan[0]
      ? _c(
          "div",
          {
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
            staticStyle: { display: "flex", "justify-content": "center" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
              },
              [
                _c(
                  "md-card",
                  [
                    _c(
                      "md-card-header",
                      {
                        staticClass: "md-card-header-icon md-card-header-blue",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-icon" },
                          [_c("md-icon", [_vm._v("important_devices")])],
                          1
                        ),
                      ]
                    ),
                    _c("md-card-content", [
                      _c("div", { staticClass: "md-layout" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c("h2", [
                                  _vm._v(_vm._s(_vm.infoPlan[0].plan.name)),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c("label", [
                                  _c("b", [_vm._v("Fim do plano")]),
                                  _vm._v(
                                    ": " + _vm._s(_vm.infoPlan[0].date_end)
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c("p"),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    "Deseja prosseguir com o cancelamento do plano?"
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-info",
                                    on: {
                                      click: function ($event) {
                                        return _vm.CancelPlan()
                                      },
                                    },
                                  },
                                  [_vm._v("Avançar")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    !_vm.loading && !_vm.plan
      ? _c(
          "div",
          {
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
              },
              [
                _c(
                  "md-card",
                  [
                    _c(
                      "md-card-header",
                      {
                        staticClass: "md-card-header-icon md-card-header-blue",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-icon" },
                          [_c("md-icon", [_vm._v("https")])],
                          1
                        ),
                      ]
                    ),
                    _c("md-card-content", [
                      _c(
                        "div",
                        {
                          staticClass: "md-layout",
                          staticStyle: { "text-align": "center" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                            },
                            [
                              _c("h2", [_vm._v("Plano Grátis")]),
                              _c("p", [
                                _vm._v(
                                  "Atualmente você contempla o plano grátis. Faço upgrade para o Plano Premium e tenha muito mais!"
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spinner" }, [
      _c("div", { staticClass: "rect1" }),
      _c("div", { staticClass: "rect2" }),
      _c("div", { staticClass: "rect3" }),
      _c("div", { staticClass: "rect4" }),
      _c("div", { staticClass: "rect5" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }